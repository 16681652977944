.section-home-top {
  height: 100vh;
  background-color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px;
    background: #8B8B8B;
    width: 85%;
  }

  &-container {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    @media (max-width: 991px) {
        gap: 25px;
    }


    .logo {
      width: 180px;
      height: 180px;
      object-fit: cover;

      @media (max-width: 991px) {
          width: 120px;
          height: 120px;
      }

      @media (max-width: 600px) {
            width: 80px;
            height: 80px;
      }
    }

    h1 {
      color: #FFF;
      text-align: center;
      font-family: Chivo;
      font-size: 96px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 991px) {
        font-size: 64px;
      }

      @media (max-width: 600px) {
          font-size: 48px;
      }

      span {
        color: #0CA4A5;
      }
    }

    p {
      color: #8B8B8B;
      text-align: center;
      font-family: Chivo;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 991px) {
          font-size: 16px;
      }

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

        @media (max-width: 991px) {
            gap: 25px;
        }
      @media (max-width: 600px) {
        flex-direction: column;
      }

      a {
        display: flex;
        padding: 15px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: Chivo;
        cursor: pointer;
        text-decoration: none;

        &.button {
          &-blue {
            border-radius: 8px;
            border: 2px solid #0CA4A5;
            background: #0CA4A5;
            color: #FFFFFF;
            font-size: 20px;

            @media (max-width: 991px) {
                font-size: 16px;
            }

            @media (max-width: 600px) {
                font-size: 12px;
            }

          }

          &-white {
            border-radius: 8px;
            border: 2px solid #FFF;
            color: #FFF;
            font-size: 20px;

            @media (max-width: 991px) {
                font-size: 16px;
            }
            @media (max-width: 600px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
