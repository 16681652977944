/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

.div-spacer {
  height: 104px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    .unselectable {
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    td {
      position: relative;

      span {
        position: absolute;
        margin-left: 5px;
      }
    }
  }

  tbody {
    a {
      color: #4AA9C2;
    }
  }

}

html {
  scroll-behavior: smooth;
}

.rdw-editor-main {
  line-height: 1.6;
}

.Toastify__toast-container {
  z-index: 999999999 !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}
